<template>
  <div class="home">
    <div class="logo">
      <img
        src="../assets/Logo.svg"
        alt="Logo Fresh Start Cleaning Company"
        width="200px"
        height="200px"
      />
    </div>
    <div class="body">
      <div class="construction">
        <div class="message">
          <h1>We're cleaning up this site!</h1>
          <p>
            No, not with mops and brooms (though we’re pros at that). We’re
            working hard to create something amazing for you. Stay tuned! Soon,
            you’ll see the shiny results of our work.
          </p>
          <p>
            In the meantime, if you need our services, feel free to reach out.
            We’re here to give your home or business a Fresh Start!
          </p>
        </div>
        <div class="buttons">
          <form @submit.prevent="submitForm" accept-charset="utf-8">
            <input
              v-model="form.name"
              type="text"
              name="name"
              id="name"
              placeholder="Name"
              required
            />
            <input
              v-model="form.email"
              type="text"
              name="email"
              id="email"
              placeholder="Email Address"
              required
            />
            <input
              v-model="form.phone"
              type="tel"
              name="telephone"
              placeholder="Phone Number"
              required
            />
            <button type="submit">CONTACT US</button>
          </form>
          <p v-if="feedbackMessage">{{ feedbackMessage }}</p>
        </div>
      </div>
      <div class="construction_img">
        <picture>
          <source srcset="../assets/working.webp" type="img/webp" />
          <img
            src="../assets/working.png"
            alt="image of a website under construction."
            width="526px"
            height="526px"
          />
        </picture>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: "HomeView",
  data() {
    return {
      form: {
        name: "",
        email: "",
        phone: "",
      },
      feedbackMessage: "",
    };
  },
  methods: {
    async submitForm() {
      const formData = new FormData();
      formData.append("name", this.form.name);
      formData.append("email", this.form.email);
      formData.append("telephone", this.form.phone);

      try {
        const response = await fetch("https://formspree.io/f/xwpkejdg", {
          method: "POST",
          body: formData,
          headers: {
            Accept: "application/json",
          },
        });

        if (response.ok) {
          this.feedbackMessage = "Message sent successfully!";
          this.form.name = "";
          this.form.email = "";
          this.form.phone = "";
        } else {
          this.feedbackMessage =
            "Failed to send message. Please try again later.";
        }
      } catch (error) {
        this.feedbackMessage = "An error ocurred while sending your message.";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@media only screen and (min-width: 851px) {
  .home {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;

    .logo {
      display: flex;
      margin-left: 50px;
      width: 100%;
      height: 250px;
      justify-content: flex-start;
    }

    .body {
      display: flex;
      flex-direction: row;
      width: 100%;
      height: auto;
      justify-content: center;
      align-items: center;

      .construction {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;

        .message {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          height: 100%;

          h1 {
            font-size: 24px;
          }

          p {
            font-size: 18px;
            margin-top: 30px;
          }
        }

        .buttons {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          height: 300px;
          width: 100%;

          form {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            input {
              width: 300px;
              padding: 10px;
              border-radius: 25px;
              height: 50px;
              margin-bottom: 20px;
              text-align: center;
              font-size: 18px;
              border: 2px solid #ffad0a;
            }

            input:focus {
              border: 2px solid #ffad0a;
            }

            input:active {
              border: 2px solid #ffad0a;
            }

            input:hover {
              border: 2px solid #ffad0a;
            }

            button {
              width: 150px;
              height: 30px;
              border-radius: 25px;
              background-color: #0aceff;
              color: #ffffff;
              border: none;
              font-size: 18px;
              font-weight: 700;
              margin-bottom: 30px;
            }

            button:hover {
              cursor: pointer;
              background-color: #4072d2;
            }
          }
        }
      }

      .construction_img {
        display: flex;
        width: 500px;
        height: 500px;
        justify-content: center;
        align-items: center;
        margin-right: 50px;
        margin-left: 50px;
        margin-bottom: 50px;

        img {
          width: 500px;
          height: 500px;
        }
      }
    }
  }
}

@media only screen and (max-width: 850px) {
  .home {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;

    .logo {
      display: flex;
      width: 100%;
      height: 250px;
      justify-content: center;
    }

    .body {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: auto;
      justify-content: space-around;
      align-items: center;

      .construction {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;

        .message {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          height: 100%;
          width: 100%;

          h1 {
            font-size: 24px;
          }

          p {
            font-size: 18px;
            margin: 30px;
          }
        }

        .buttons {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          height: 300px;
          width: 100%;

          form {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            input {
              width: 300px;
              padding: 10px;
              border-radius: 25px;
              height: 50px;
              margin-bottom: 20px;
              text-align: center;
              font-size: 18px;
              border: 2px solid #ffad0a;
            }

            input:focus {
              border: 2px solid #ffad0a;
            }

            input:active {
              border: 2px solid #ffad0a;
            }

            input:hover {
              border: 2px solid #ffad0a;
            }

            button {
              width: 150px;
              height: 30px;
              border-radius: 25px;
              background-color: #0aceff;
              color: #ffffff;
              border: none;
              font-size: 18px;
              font-weight: 700;
              margin-bottom: 30px;
            }

            button:hover {
              cursor: pointer;
              background-color: #4072d2;
            }
          }
        }
      }

      .construction_img {
        display: flex;
        width: 300px;
        height: 300px;
        justify-content: center;
        align-items: center;
        margin-right: 50px;
        margin-left: 50px;
        margin-bottom: 50px;

        img {
          width: 300px;
          height: 300px;
        }
      }
    }
  }
}
</style>
